<template>
  <div class="page" style="position: relative; background: #f3f5f6; border-radius: 4px; overflow: hidden;">
    <div class="lefttype">
      <div :class="['txitem',lefttype=='tx'?'txckd':'']" @click="lefttype='tx'">
        <i class="iconfont">&#xe616;</i>
        <div>题型</div>
      </div>
      <div :class="['txitem',lefttype=='skin'?'txckd':'']" @click="lefttype='skin'">
        <i class="iconfont">&#xe60b;</i>
        <div>外观</div>
      </div>
      <!-- <div :class="['txitem',lefttype=='logic'?'txckd':'']" @click="lefttype='logic'">
        <i class="iconfont" style="font-size: 22px;">&#xe644;</i>
        <div>逻辑</div>
      </div> -->
      <!-- <div :class="['txitem',lefttype=='target'?'txckd':'']" @click="lefttype='target'">
        <i class="iconfont" style="font-size: 22px;">&#xe6af;</i>
        <div>对象</div>
      </div>
      <div :class="['txitem',lefttype=='setting'?'txckd':'']" @click="lefttype='setting'">
        <i class="iconfont" style="font-size: 22px;">&#xe649;</i>
        <div>设置</div>
      </div> -->
    </div>
    <div class="lefttm">
      <div style="padding: 10px;" v-if="lefttype=='tx'">
        <div>
          <div style="padding-bottom: 6px;">选择题</div>
          <div class="tmlx" @click="addTm('radio_normal')"><i class="iconfont"
                                                              style="font-size: 16px; vertical-align: middle;">&#xe601;</i>单
            选 题</div>
          <div class="tmlx" @click="addTm('checkbox_normal')" style="margin-left: 10%;"><i class="iconfont"
                                                                                           style="font-size: 16px; vertical-align: middle;">&#xe6e5;</i>多
            选 题</div>
          <div class="tmlx" @click="addTm('radio_image')"><i class="iconfont"
                                                             style="font-size: 16px; vertical-align: middle;">&#xe601;</i>图片单选</div>
          <div class="tmlx" @click="addTm('checkbox_image')" style="margin-left: 10%;"><i class="iconfont"
                                                                                          style="font-size: 16px; vertical-align: middle;">&#xe6e5;</i>图片多选</div>
        </div>
        <div style="padding-top: 20px;">
          <div style="padding-bottom: 6px;">输入题</div>
          <div class="tmlx" @click="addTm('tiankong')"><i class="iconfont"
                                                          style="font-size: 16px; vertical-align: middle;">&#xe61d;</i>填空题</div>
          <div class="tmlx" @click="addTm('wenda')" style="margin-left: 10%;"><i class="iconfont"
                                                                                 style="font-size: 16px; vertical-align: middle;">&#xe61d;</i>
            问答题</div>
        </div>
        <div style="padding-top: 20px;">
          <div style="padding-bottom: 6px;">投票题</div>
          <div class="tmlx" @click="addTm('vote_normal')"><i class="iconfont"
                                                             style="font-size: 16px; vertical-align: middle;">&#xe621;</i>文字投票</div>
          <div class="tmlx" @click="addTm('vote_image')" style="margin-left: 10%;"><i class="iconfont"
                                                                                      style="font-size: 16px; vertical-align: middle;">&#xe60e;</i>
            图片投票</div>
        </div>
        <div style="padding-top: 20px;" v-if="false">
          <div style="padding-bottom: 6px;">量表题</div>
          <div class="tmlx" @click="addTm('chart_normal')"><i class="iconfont"
                                                              style="font-size: 16px; vertical-align: middle;">&#xe61d;</i>文字量表</div>
          <div class="tmlx" @click="addTm('chart_image')" style="margin-left: 10%;"><i class="iconfont"
                                                                                       style="font-size: 16px; vertical-align: middle;">&#xe625;</i>
            图片量表</div>
        </div>


        <div style="padding-top: 60px;">
          <div style="padding-bottom: 6px;">适用对象</div>
          <div>
            <div @click="setTarget('student')" :class="['targetItem',investigation.target_user.indexOf('student')>=0?'tckd':'']">
              学生
            </div>
            <div @click="setTarget('parent')" :class="['targetItem',investigation.target_user.indexOf('parent')>=0?'tckd':'']">
              家长
            </div>
            <div @click="setTarget('enterpirse')" :class="['targetItem',investigation.target_user.indexOf('enterpirse')>=0?'tckd':'']">
              企业
            </div>
          </div>
          <div style="padding-bottom: 6px;margin-top: 20px;">状态</div>
          <div>
            <el-radio-group v-model="investigation.deleted">
              <el-radio :label="0">启用</el-radio>
              <el-radio :label="1">不启用</el-radio>
            </el-radio-group>

          </div>
        </div>
      </div>
      <div v-if="lefttype=='skin'">
        <div style="text-align: center; padding-top: 20px;">
          <el-upload action="" :multiple="true" :http-request="uploadOss" :on-success="uploadHeadImage"
                     :show-file-list="false" name="image">
            <el-button size='mini' icon="el-icon-plus">头图</el-button>
          </el-upload>

        </div>
        <div style="text-align: center; padding-top: 20px;">
          <el-upload action="" :multiple="true" :http-request="uploadOss" :on-success="uploadBgImage"
                     :show-file-list="false" name="image">
            <el-button size='mini' icon="el-icon-plus">背景图</el-button>
          </el-upload>

        </div>
      </div>
      <div v-if="lefttype=='logic'">
        <div style="text-align: center; padding-top: 20px;">
          请根据得分设置最终结果
        </div>
      </div>
      <div v-if="lefttype=='setting'">
        <div style="text-align: center; padding-top: 20px;">
          问卷设置
        </div>
      </div>
      <div v-if="lefttype=='target'">
        <div style="text-align: center; padding-top: 20px;">
          选择发送对象
        </div>
      </div>
    </div>
    <div class="righttm">
      <div id="tmdescbox"
           style="width:0%; height: 100%; float: right; overflow: hidden; background: #ffffff; border-radius: 4px;">
        <div style="width: 260px; height: 100%;">
          <div style="padding: 10px;">
            <div style="display: inline-block; padding: 4px 10px; background: #495165; color: #FFFFFF;">
              <span>第 {{ckdIdx}} 题 -- </span>
              <span>
								{{ckdTM.tm_type=='radio_normal'?'单选题':''}}
								{{ckdTM.tm_type=='checkbox_normal'?'多选题':''}}
								{{ckdTM.tm_type=='radio_image'?'图片单选题':''}}
								{{ckdTM.tm_type=='checkbox_image'?'图片多选题':''}}
								{{ckdTM.tm_type=='vote_normal'?'文字投票题':''}}
								{{ckdTM.tm_type=='vote_image'?'图片投票题':''}}
								{{ckdTM.tm_type=='chart_normal'?'文字量表题':''}}
								{{ckdTM.tm_type=='chart_image'?'图片量表题':''}}
								{{ckdTM.tm_type=='tiankong'?'填空':''}}
								{{ckdTM.tm_type=='wenda'?'问答':''}}
							</span>

            </div>

          </div>
          <div style="padding: 20px;" v-if="!ckdXx">
            <div style="padding: 20px 0; border-bottom: 1px solid #f6f6f6; color: #888888;">
              <div style="float: right;">
                <el-switch v-model="ckdTM.required">
                </el-switch>
              </div>
              <div>此题必答</div>

            </div>
            <div style="padding: 20px 0; border-bottom: 1px solid #f6f6f6; color: #888888;">
              <div style="float: right;">
                <el-switch v-model="ckdTM.xx_random">
                </el-switch>
              </div>
              <div>选项随机排列</div>

            </div>

            <div v-show="ckdTM.tm_type.indexOf('checkbox')>=0"
                 style="padding: 20px 0; border-bottom: 1px solid #f6f6f6; color: #888888;">
              <div style="float: right; margin-top: -3px;">
                <el-input size="mini" v-model="ckdTM.min_cnt" style="width: 60px;"></el-input>
              </div>
              <div>最少选择个数</div>

            </div>

            <div v-show="ckdTM.tm_type.indexOf('checkbox')>=0"
                 style="padding: 20px 0; border-bottom: 1px solid #f6f6f6; color: #888888;">
              <div style="float: right; margin-top: -3px;">
                <el-input size="mini" v-model="ckdTM.max_cnt" style="width: 60px;"></el-input>
              </div>
              <div>最多选择个数</div>

            </div>

            <div v-show="ckdTM.tm_type.indexOf('tiankong')>=0"
                 style="padding: 20px 0; border-bottom: 1px solid #f6f6f6; color: #888888;">

              <div>填空题输入框的格式</div>
              <div style="font-size: 12px;">_${省市区}_</div>
              <div style="font-size: 12px;">_${省市}_</div>
              <div style="font-size: 12px;">_${现在时间}_</div>
              <div style="font-size: 12px;">_${选择时间}_</div>
              <div style="font-size: 12px;">_${姓名}_</div>
              <div style="font-size: 12px;">_${手机号}_</div>
              <div style="font-size: 12px;">_${数字}_</div>
              <div style="font-size: 12px;">_${下拉框}_</div>
              <div style="font-size: 12px;">_${普通输入框}_</div>
            </div>

            <div v-show="ckdTM.tm_type.indexOf('tiankong')>=0"
                 style="padding: 20px 0; border-bottom: 1px solid #f6f6f6; color: #888888;">

              <div>如果使用了下拉框，请将下拉选项填在这里，使用【、，,换行】分割</div>
              <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="请输入选项"
                  v-model="ckdTM.selectItems">
              </el-input>
            </div>




          </div>

          <div style="padding: 20px;" v-else>
            <div style="display: inline-block; padding: 4px 10px; background: #495165; color: #FFFFFF;">
              <span>选项{{ckdXxIdx+1}}</span>


            </div>
            <div style="padding: 20px 0; border-bottom: 1px solid #f6f6f6; color: #888888;">
              <div style="float: right;">
                <el-switch v-model="ckdXx.is_correct">
                </el-switch>
              </div>
              <div>此项正确</div>

            </div>
            <div style="padding: 20px 0; border-bottom: 1px solid #f6f6f6; color: #888888;">
              <div style="float: right; margin-top: -3px;">
                <el-input size="mini" v-model="ckdXx.score" style="width: 60px;"></el-input>
              </div>
              <div>此项分值</div>

            </div>

            <div v-show="ckdTM.tm_type.indexOf('checkbox')>=0"
                 style="padding: 20px 0; border-bottom: 1px solid #f6f6f6; color: #888888;">

              <div>选此项不能选第几项（填选项序号，1开始，多项逗号分隔）</div>

              <div style="">
                <el-input size="mini" v-model="ckdXx.xxLimit" style="width: 90%;"></el-input>
              </div>

            </div>

            <div v-show="ckdTM.tm_type.indexOf('checkbox')>=0||ckdTM.tm_type.indexOf('radio')>=0"
                 style="padding: 20px 0; border-bottom: 1px solid #f6f6f6; color: #888888;">

              <div>选此项不可答第几题（题目ID，多项逗号分隔）</div>

              <div style="">
                <el-input size="mini" v-model="ckdXx.tmLimit" style="width: 90%;"></el-input>
              </div>

            </div>




          </div>


        </div>
      </div>

    </div>
    <div class="midcontent" style="">
      <div v-if="lefttype=='tx'">
        <div class="tmbox">
          <div>
            <div style="font-size: 18px;" v-if="!tmheader.title_ckd" class="canedit" @click="chooseTitle">
              {{tmheader.title?tmheader.title:'请输入标题'}}</div>
            <div v-else class="inputbar">
              <input v-model="tmheader.title" @blur="tmheader.title_ckd = false" />
            </div>
          </div>
          <div style="margin-top: 10px;">

            <UEditor ref="ue" @ready="editorReady" style="width: 100%;"></UEditor>





          </div>

        </div>

        <div @drop="setIdx" :class="['tmbox',tm.ckd?'tmboxckd':'']" @click="chooseOneTm(idx)" :draggable="true"
             v-for="(tm,idx) in tm_list">
          <section class="tmnum">
            第 {{idx+1}} 题：题目ID:{{tm.id}}
          </section>
          <div>
            <div v-if="!tm.title_ckd" class="canedit" @click="chooseTm($event,tm)">
              {{tm.tm_title?tm.tm_title:'请输入题目标题'+(tm.tm_type=='tiankong'?'，填空题请参照右侧输入类型':'')}}</div>
            <div v-else class="inputbar">
              <input v-model="tm.tm_title" @blur="tm.title_ckd = false" />
            </div>
          </div>
          <div style="padding:10px 20px;">
            <div class="xxitem" v-for="(xx,i) in tm.xxlist">
              <div class="xxicon">
                <i v-if="tm.tm_type.indexOf('checkbox')<0" class="iconfont"
                   style="font-size: 20px; vertical-align: middle;">&#xe619;</i>
                <i v-else class="iconfont" style="font-size: 20px; vertical-align: middle;">&#xe622;</i>
              </div>
              <div class="xxcontent" v-if="tm.tm_type.indexOf('image')<0">
                <div v-if="!xx.title_ckd" class="canedit" @click.stop="chooseXx($event,xx,tm,idx,i)">
                  {{xx.title?xx.title:'请输入选项标题'}}</div>
                <div v-else class="inputbar">
                  <input v-model="xx.title" @blur="xx.title_ckd = false" />
                </div>
              </div>
              <div class="xxcontent" v-else>
                <div class="btnupload" :style="{backgroundImage:'url('+xx.value+')'}"
                     @click.stop="chooseXx($event,xx,tm,idx,i)">
                  <el-upload action="" :multiple="true" :http-request="uploadOss"
                             :on-success="uploadAttch" :show-file-list="false" name="image">
                    <i v-if="!xx.value" class="iconfont"
                       style="vertical-align: middle; font-size: 30px;">&#xe632;</i>
                    <div v-else style="width: 90px; height: 90px;"></div>
                  </el-upload>

                </div>
                <div v-if="!xx.title_ckd" class="canedit" @click.stop="chooseXx($event,xx,tm,idx,i)">
                  {{xx.title?xx.title:'请输入选项标题'}}</div>
                <div v-else class="inputbar">
                  <input v-model="xx.title" @blur="xx.title_ckd = false" />
                </div>
              </div>
              <section class="xxbtms">
                <div title="上移">
                  <i class="iconfont" @click.stop="xxMoveUp(xx,i,idx)"
                     style="vertical-align: middle;">&#xe602;</i>
                </div>
                <div title="下移">
                  <i class="iconfont" @click.stop="xxMoveDown(xx,i,idx)"
                     style="vertical-align: middle;">&#xe70f;</i>
                </div>
                <div title="删除">
                  <i class="iconfont" @click.stop="deleteXX(xx,i,idx)"
                     style="vertical-align: middle;">&#xe631;</i>
                </div>
              </section>
            </div>

            <div style="color: #007AFF;" v-if="tm.ckd&&tm.tm_type!='tiankong'&&tm.tm_type!='wenda'">
							<span @click="addTmXx(tm)" style="cursor: pointer;"> <i class="iconfont"
                                                                      style="font-size:14px; vertical-align: middle;">&#xe618;</i>
								添加选项</span>
            </div>
          </div>
          <section class="tmtool">
            <div class="ico-move" v-if="false">
              <i class="iconfont" style="font-size: 16px; vertical-align: middle;">&#xe600;</i>
            </div>
            <div class="ico-delete" @click.stop="deleteTM(tm,idx)">
              <i class="iconfont" style="font-size: 16px; vertical-align: middle;">&#xe617;</i>
            </div>

          </section>
        </div>
      </div>

      <div v-if="lefttype=='skin'">
        <div style="width: 400px; position: relative;">
          <img src="//smart-scholl-notify.oss-cn-hangzhou.aliyuncs.com/files/2019-12/1575369370292536.png?s=548_1084"
               style="vertical-align: top; width: 100%;" />
          <div class="ivstbox" :style="{backgroundImage:'url('+investigation.ivst_bgimg+')'}">
            <img :src="investigation.ivst_headimg" style="vertical-align: top;width: 100%;" />
            <div style="word-break: break-all; padding: 10px;">
              <div style="font-size: 18px;">{{tmheader.title}}</div>
              <div style="font-size: 14px;">{{tmheader.desc}}</div>

            </div>
          </div>


        </div>

      </div>
      <div v-show="lefttype=='target'">
        <div style=" text-align: right; padding-right: 10px; color: #ccc;">按Ctrl+F进行搜索</div>
        <div style=" position: relative;">
          <div style="position: absolute; right: 20px; top: 20px; z-index: 2;">
            <el-button icon="el-icon-circle-check" size="mini" @click="checkAll">
              {{checkedAllDept?'全不选':'全选'}}</el-button>
          </div>

          <el-tree @check='setDeptNodeCheck' ref="dept_tree" default-expand-all show-checkbox node-key="id"
                   :data="DeptData" :props="defaultProps">
          </el-tree>


        </div>
      </div>
      <div v-if="lefttype=='logic'">
        <div style="background: #FFFFFF; min-width: 690px; padding: 20px; border-radius: 4px; margin-bottom: 10px;"
             v-for="(lg,idx) in investigation.result_config.logic" :key="idx">
          <div>
            <div style="display: inline-block;">
              当结果：
            </div>
            <div style="display: inline-block;">
              <el-select style="width: 120px;" v-model="lg.max_ct">
                <el-option label="大于" :value="0"></el-option>
                <el-option label="大于等于" :value="1"></el-option>
              </el-select>
            </div>
            <div style="display: inline-block;">
              <el-input type="number" v-model="lg.max" style="width: 120px;"></el-input>
            </div>

            <div style="display: inline-block;">
              并且
            </div>
            <div style="display: inline-block;">
              <el-select style="width: 120px;" v-model="lg.min_ct">
                <el-option label="小于" :value="0"></el-option>
                <el-option label="小于等于" :value="1"></el-option>
              </el-select>
            </div>
            <div style="display: inline-block;">
              <el-input type="number" v-model="lg.min" style="width: 120px;"></el-input>
            </div>


            <section style="display: inline-block; color: #888888; cursor: pointer;"
                     @click="removeLogic(idx)">

              <div title="删除">
                <i class="iconfont" style="vertical-align: middle;">&#xe631;</i>
              </div>
            </section>

          </div>

          <div style="width: 600px; padding-left: 61px; padding-top: 10px;">
            结果：
            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 400}" placeholder="请输入结果内容"
                      v-model="lg.result">
            </el-input>
          </div>

        </div>

        <div style="text-align: center; padding: 20px 0;">
          <el-button size='mini' icon="el-icon-plus" @click="addLogic">添加逻辑</el-button>
        </div>


      </div>
      <div v-if="lefttype=='setting'">
        <div style="background: #ffffff; padding: 20px; border-radius: 4px;">
          <table style=" width: 100%;">
            <tr>
              <td style="width: 120px; text-align: center;">分享头图</td>
              <td style="">
                <el-upload action="" :multiple="true" :http-request="uploadOss"
                           :on-success="uploadShareImage" :show-file-list="false" name="image">
                  <div
                      style="width: 200px;height: 160px; border: 1px solid #f6f6f6; text-align: center; line-height: 160px;">
                    <i v-if="!investigation.share_img" class="iconfont"
                       style="vertical-align: middle; font-size: 30px;">&#xe632;</i>
                    <img v-else :src="investigation.share_img"
                         style="width: 100%; height: 100%; vertical-align: middle;" />
                  </div>
                </el-upload>


                <div style="text-align: center; width: 200px; padding-top: 10px;">
                  图片比例 5:4
                </div>

              </td>
            </tr>
            <tr v-if="false">
              <td style="width: 120px; text-align: center; height: 50px;">实名问卷</td>
              <td style="">

                <el-switch v-model="investigation.ivst_auth">
                </el-switch>

                <span style="font-size: 12px; margin-left: 20px; color: #888888;">必须登录后才可参与</span>

              </td>
            </tr>
            <tr>
              <td style="width: 120px; text-align: center; height: 50px;">截止时间</td>
              <td style="">

                <el-date-picker v-model="investigation.enddate" type="date" value-format="yyyy-MM-dd"
                                placeholder="请选择问卷截止时间"></el-date-picker>

              </td>
            </tr>
            <tr>
              <td style="width: 120px; text-align: center; height: 50px;">题目随机</td>
              <td style="">

                <el-switch v-model="investigation.tm_random">
                </el-switch>

                <span style="font-size: 12px; margin-left: 20px; color: #888888;">参与问卷时题目顺序随机</span>

              </td>
            </tr>
            <tr>
              <td style="width: 120px; text-align: center; height: 50px;">完成后看结果</td>
              <td style="">

                <el-switch v-model="investigation.can_viewresult">
                </el-switch>

                <span style="font-size: 12px; margin-left: 20px; color: #888888;">提交问卷后可以查看逻辑计算结果</span>

              </td>
            </tr>
            <tr>
              <td style="width: 120px; text-align: center; height: 50px;">完成后看统计</td>
              <td style="">

                <el-switch v-model="investigation.can_view_tj">
                </el-switch>

                <span
                    style="font-size: 12px; margin-left: 20px; color: #888888;">提交问卷后可以查看所有参与用户的统计结果</span>

              </td>
            </tr>
            <tr>
              <td style="width: 120px; text-align: center; height: 50px;">匿名作答</td>
              <td style="">

                <el-switch v-model="investigation.noname">
                </el-switch>

                <span style="font-size: 12px; margin-left: 20px; color: #888888;">不记录答题用户名称</span>

              </td>
            </tr>

          </table>
        </div>
      </div>


    </div>
    <div class="btn_save" @click="saveForm(1)">保存</div>
    <!-- <div class="btn_preview" @click="saveForm(0)">保存并返回</div> -->

  </div>
</template>

<script>
import UEditor from '../../../components/ueditor.vue'
//ivst_type 问卷类型--->问卷调查，投票评选，考试测评，量表测试
export default {
  components:{
    UEditor
  },

  data() {
    return {
      ivst_type: [
        "问卷调查",
        "投票评选",
        "考试测评",
        "量表测试"
      ],
      lefttype: 'tx',
      investigation: {
        id: 0,
        ivst_type: "",
        ivst_title: "",
        ivst_desc: "",
        ivst_headimg: "",
        ivst_bgimg: "",
        ivst_auth: true,

        ivst_starttime: "",
        ivst_endtime: "",
        can_share: 1,
        share_img: "https://smart-scholl-notify.oss-cn-hangzhou.aliyuncs.com/files/2019-12/1576477311421897.jpeg?s=500_332",
        can_viewresult: false,
        can_view_tj: false,
        result_config: {
          logic: []
        },
        status: 0,
        tm_random: false,
        target_user: "",
        enddate: "",
        noname: false,
        deleted:0,
      },
      tm_temp: {
        tm_title: "",
        tm_desc: "",
        tm_type: "",
        tm_content: "",
        required: true,
        ckd: false,
        min_cnt: 1,
        max_cnt: 99,
        xx_random: false,
        title_ckd: false, //是否在编辑标题
        xxlist: []
      },
      tm_list: [],
      tmheader: {
        title: "",
        desc: "",
        title_ckd: false,
        desc_ckd: false,
      },
      ckdTM: {
        tm_title: "",
        tm_desc: "",
        tm_type: "",
        tm_content: "",
        required: true,
        ckd: false,
        xx_random: false,
        min_cnt: 1,
        max_cnt: 99,
        title_ckd: false, //是否在编辑标题
        selectItems:""
      },
      xxitem: {
        title: "",
        value: "",
        is_correct: false,
        score: "",
        title_ckd: false,
        ckd: false,
        xxLimit:"",//选此项不能选第几项
        tmLimit:"",//选此项不能选第几题

      },
      ckdIdx: -1,
      ckdXx: null,
      ckdXxIdx: -1,
      DeptData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      checkedAllDept: false,
      editor:null

    }
  },

  mounted() {
    $(".midcontent").height($(window).height() - 50);
    if (this.$route.params.id && this.$route.params.id > 0) {
      this.investigation.id = this.$route.params.id;
      this.getOne();
    }
  },
  methods: {
    editorReady(editorInstance) {
      this.editor = editorInstance;
    },
    getDepts() {
      this.$http.post('/admin/department', {}).then(res => {


        for (let r1 of res.data) {

          for (let r2 of r1.children) {
            for (let t of r2.teachers) {
              for (let r3 of r2.children) {
                if (r3.teachers) {
                  for (let t2 of r3.teachers) {
                    t2.id = t.union_id
                    r3.children.push(t2)
                  }
                }
              }
              t.id = t.union_id
              r2.children.push(t)
            }
          }
          for (let t of r1.teachers) {
            t.id = t.union_id
            r1.children.push(t)
          }


        }

        this.DeptData = res.data;


      });
    },
    checkAll() {

      if (this.checkedAllDept) {
        this.checkedAllDept = false
        this.$refs['dept_tree'].setCheckedKeys([]);

      } else {
        this.checkedAllDept = true;
        let users = []
        for (let dept of this.DeptData) {
          if (dept.teachers) {
            for (let teacher of dept.teachers) {
              users.push(teacher.id)
            }
          }
          if (dept.children) {
            for (let sdept of dept.children) {
              if (sdept.teachers) {
                for (let teacher of sdept.teachers) {
                  users.push(teacher.id)
                }
              }
            }
          }

        }
        this.$refs['dept_tree'].setCheckedKeys(users);

      }
    },
    setDeptNodeCheck() {
      this.checkedAllDept = false
    },
    setCheckedDeptUser(userlist) {
      this.$refs['dept_tree'].setCheckedKeys(userlist);
    },
    chooseOneTm(idx) {
      this.tm_list.map(e => {
        e.ckd = false;
      });
      this.tm_list[idx].ckd = true;
      this.ckdTM = this.tm_list[idx];

      this.ckdXx = null;
      if (this.ckdIdx != idx + 1) {
        $("#tmdescbox").width(0).animate({
          width: "100%"
        }, 300, "ease-out");
        this.ckdIdx = idx + 1;
      }


    },
    addTm(type) {
      let tmitem = JSON.parse(JSON.stringify(this.tm_temp))
      tmitem.tm_type = type;
      if(type=="tiankong"||type=="wenda"){

      }else{
        let xxitem1 = JSON.parse(JSON.stringify(this.xxitem))
        let xxitem2 = JSON.parse(JSON.stringify(this.xxitem))
        tmitem.xxlist = [xxitem1, xxitem2];
      }


      this.tm_list.push(tmitem)
      this.$nextTick(() => {
        $(".midcontent").scrollTop(999999)
      })
    },
    addTmXx(tm) {
      let xxitem = JSON.parse(JSON.stringify(this.xxitem))
      tm.xxlist.push(xxitem)
    },
    addLogic() {
      let log = {
        min: 0,
        max: 0,
        min_ct: 0, //包含最小值
        max_ct: 0, //包含最大值
        result: ''
      };
      this.investigation.result_config.logic.push(log)

    },
    removeLogic(idx) {
      this.investigation.result_config.logic.splice(idx, 1)
    },
    chooseTitle(e) {
      this.tmheader.title_ckd = true;
      this.$nextTick(() => {
        $(e.target).parent().find("input").focus()
      })

    },
    chooseDesc(e) {
      this.tmheader.desc_ckd = true;
      this.$nextTick(() => {
        $(e.target).parent().find("input").focus()
      })
    },
    chooseTm(e, tm) {
      tm.title_ckd = true;
      this.ckdXx = null;
      this.$nextTick(() => {
        $(e.target).parent().find("input").focus()
      })
    },
    chooseXx(e, xx, tm, idx, i) {

      this.chooseOneTm(idx)
      xx.title_ckd = true;
      this.ckdXx = xx;
      this.ckdXxIdx = i;
      console.log(xx)
      this.$nextTick(() => {
        $(e.target).parent().find("input").focus()

      })


    },
    deleteTM(tm, idx) {

      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (tm.id > 0) {
          this.$http.post("api/ivst_delete_tm", {
            id: tm.id
          }).then(res => {
            this.tm_list.splice(idx, 1)
          })
        } else {
          this.tm_list.splice(idx, 1)
        }

      }).catch(() => {

      });
    },
    deleteXX(xx, i, idx) {
      this.tm_list[idx].xxlist.splice(i, 1)
    },
    xxMoveUp(xx, i, idx) {
      if (i > 0) {
        let xxlist = JSON.parse(JSON.stringify(this.tm_list[idx].xxlist));
        let old = xxlist[i];
        let n = xxlist[i - 1]

        xxlist[i] = n;
        xxlist[i - 1] = old;
        this.tm_list[idx].xxlist = xxlist;
      }

    },
    xxMoveDown(xx, i, idx) {

      if (i < this.tm_list[idx].xxlist.length - 1) {
        let xxlist = JSON.parse(JSON.stringify(this.tm_list[idx].xxlist));
        let old = xxlist[i];
        let n = xxlist[i + 1]

        xxlist[i] = n;
        xxlist[i + 1] = old;

        this.tm_list[idx].xxlist = xxlist;
      }
    },
    uploadAttch(e) {
      this.ckdXx.value = e.src;
    },
    uploadHeadImage(e) {
      this.investigation.ivst_headimg = e.src;
    },
    uploadBgImage(e) {
      this.investigation.ivst_bgimg = e.src;
    },
    uploadShareImage(e) {
      this.investigation.share_img = e.src;
    },
    getOne() {
      this.$http.post("api/ivst_one", {
        id: this.investigation.id
      }).then(res => {
        let ivst = res.data;
        ivst.result_config = JSON.parse(ivst.result_config);
        ivst.ivst_auth ? ivst.ivst_auth = true : ivst.ivst_auth = false;
        ivst.can_share ? ivst.can_share = true : ivst.can_share = false;
        ivst.can_viewresult ? ivst.can_viewresult = true : ivst.can_viewresult = false;
        ivst.tm_random ? ivst.tm_random = true : ivst.tm_random = false;
        ivst.can_view_tj ? ivst.can_view_tj = true : ivst.can_view_tj = false;
        ivst.noname ? ivst.noname = true : ivst.noname = false;

        this.tmheader.title = ivst.ivst_title;
        this.tmheader.desc = ivst.ivst_desc;

        setTimeout(()=>{
          this.editor.setContent(this.tmheader.desc)
        },1000)



        for (let tm of ivst.tm_list) {
          tm.xxlist = JSON.parse(tm.tm_content);
          tm.required > 0 ? tm.required = true : tm.required = false;

          tm.xx_random > 0 ? tm.xx_random = true : tm.xx_random = false;
          for(let xx of tm.xxlist){
            if(!xx.tmLimit) xx.tmLimit=""
            if(!xx.xxLimit) xx.xxLimit=""
          }
          if(!tm.selectItems) tm.selectItems=""
        }
        this.tm_list = ivst.tm_list
        delete ivst.tm_list;
        this.investigation = ivst;
        setTimeout(() => {
          let arr = [];
          let users = ivst.target_user.split(",")

          for (let item of users) {
            if (item && item != "0") {
              arr.push(item)
            }
          }

          this.setCheckedDeptUser(arr)

        }, 2000)


      })
    },
    setTarget(t){
      if(this.investigation.target_user.indexOf(t)>=0){
        this.investigation.target_user = this.investigation.target_user.replace(t,"")
      }else {
        this.investigation.target_user = this.investigation.target_user+t
      }


    },
    setIdx(e){
      e.preventDefault()
      console.log(950,e)
    },

    saveForm(status) {




      if(!this.investigation.target_user){
        this.$message.error("请选择适用对象")
        return
      }



      let ivst = JSON.parse(JSON.stringify(this.investigation));
      ivst.result_config = JSON.stringify(ivst.result_config);
      ivst.ivst_auth ? ivst.ivst_auth = 1 : ivst.ivst_auth = 0;
      ivst.can_share ? ivst.can_share = 1 : ivst.can_share = 0;
      ivst.can_viewresult ? ivst.can_viewresult = 1 : ivst.can_viewresult = 0;
      ivst.tm_random ? ivst.tm_random = 1 : ivst.tm_random = 0;
      ivst.can_view_tj ? ivst.can_view_tj = 1 : ivst.can_view_tj = 0;
      ivst.status = status;
      ivst.ivst_title = this.tmheader.title;
      ivst.ivst_desc = this.editor.getContent();
      ivst.tmlist = this.tm_list;
      console.log(ivst)
      this.$http.post("api/ivst_edit", ivst).then(res => {
        if (res.data.id) {
          this.investigation.id = res.data.id;
        }
        this.$message({
          type: 'success',
          message: '保存成功'
        });

        // setTimeout(() => {
        //   this.$router.go(-1)
        // }, 800)
        //

        setTimeout(() => {
          window.location.reload()
        }, 800)


      })

    }


  }
}
</script>
<style scoped>
.contentbox {
  height: 100%;
}

.lefttype {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 70px;
  border-right: 1px solid #d8d8d8;
  background: #f6f6f6;
  padding-top: 10px;
}

.lefttm {
  position: absolute;
  left: 70px;
  top: 0;
  height: 100%;
  width: 230px;
  border-right: 1px solid #ffffff;
  background: #FFFFFF;
}

.righttm {
  width: 260px;
  position: absolute;
  right: 10px;
  height: 100%;

}

.midcontent {
  margin: 0 280px 0 320px;
  height: 100%;
  overflow: auto;
  padding-right: 10px;
}

/* 设置滚动条的样式 */
.midcontent::-webkit-scrollbar {
  width: 0px;
}

/* 滚动槽 */
.midcontent::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
}

/* 滚动条滑块 */
.midcontent::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #bbb;
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.5);
}

.midcontent::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 0, 0, 0.4);
}

.txitem {
  cursor: pointer;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  color: #484848;
  padding: 10px 0;
  border-radius: 4px;

}

.txitem div {
  font-size: 12px;
}

.txitem:hover {
  color: #007AFF;
}

.txckd {
  background: #007AFF;
  color: #FFFFFF;
}

.txckd:hover {
  color: #FFFFFF;
}

.tmlx {
  display: inline-block;
  width: 40%;
  text-align: center;
  font-size: 12px;
  box-shadow: 0 0 1px 1px #d8d8d8;
  padding: 5px 0;
  border-radius: 3px;
  cursor: pointer;
  color: #707070;
  margin-bottom: 10px;
}

.tmlx:hover {
  box-shadow: 0 0 2px 2px #007AFF;
  color: #007AFF;
}

.tmbox {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 10px;
  border: 2px solid #FFFFFF;
  position: relative;
  word-break: break-all;

}

.tmboxckd {
  border: 2px solid #007AFF;
}



.tmbox>div {
  width: 90%;
}

.tmtool {
  position: absolute;
  right: 30px;
  height: 100%;
  top: 0;
  color: #aaaaaa;
}

.ico-move {
  position: absolute;
  top: 10px;
  cursor: pointer;
  opacity: 0;
}

.ico-delete {
  position: absolute;
  bottom: 10px;
  cursor: pointer;
  opacity: 0;
}

.tmbox:hover .ico-move {
  opacity: 1;
}

.tmbox:hover .ico-delete {
  opacity: 1;
}

.ico-move:hover {
  color: #007AFF;
  cursor: move;
}

.ico-delete:hover {
  color: orangered;
}

.canedit {
  border: 1px solid #FFFFFF;
  padding: 10px;
}

.canedit:hover {
  border: 1px dotted #d8d8d8;
}


.inputbar>input {
  padding: 0;
  margin: 0;
  height: 40px;
  outline: none;
  background: #f6f6f6;
  border: none;
  width: 96%;
  padding: 0 2%;
  font-size: 16px;
}

.tmnum {
  position: absolute;
  top: 0;
  left: 8px;
  font-size: 12px;
  color: #888888;
}

.xxitem {
  padding-left: 20px;
  margin-bottom: 8px;
  padding-right: 50px;
  position: relative;
}

.xxicon {
  float: left;
  color: #888888;
  margin-top: 9px;
  margin-left: 10px;
}

.xxcontent {
  margin-left: 40px;
}

.xxbtms {
  opacity: 0;
  position: absolute;
  right: -30px;
  top: 8px;
  width: 70px;
  font-size: 16px;
  color: #007AFF;
}

.xxbtms>div {
  display: inline-block;
}

.xxitem:hover>.xxbtms {
  opacity: 1;
}

.btnupload {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dotted #f6f6f6;
  color: #888;
  background-size: cover;
  margin-bottom: 5px;
}

.btn_save {
  position: absolute;
  width: 90px;
  padding: 8px 18px;
  background: #007AFF;
  color: #FFFFFF;
  right: 25px;
  bottom: 30px;
  z-index: 2;
  border: 1px solid #007AFF;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
}

.btn_preview {
  position: absolute;
  width: 70px;
  padding: 8px 18px;
  background: #FFFFFF;
  color: #007AFF;
  right: 145px;
  bottom: 30px;
  z-index: 2;
  border: 1px solid #007AFF;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
}

.ivstbox {
  position: absolute;
  width: 80%;
  left: 10%;
  height: 72.5%;
  top: 13.5%;
  background-size: cover;
  overflow: auto;
}

/* 设置滚动条的样式 */
.ivstbox::-webkit-scrollbar {
  width: 0px;
}

.targetItem {
  display: inline-block;
  border: 1px solid #d8d8d8;
  padding: 4px 10px;
  margin: 0 10px 10px 0;
  cursor: pointer;
  border-radius: 4px;
}
.tckd{
  border: 1px solid #007AFF;
  background-color: #007AFF;
  color: #fff;
}
</style>
